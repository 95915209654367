import repository from './repository'

const resource = 'stocks'

export default {
  index (params) {
    return repository.get(resource, { params })
  },
  store (data) {
    return repository.post(resource, data)
  },
  update (id, data) {
    return repository.put(`${resource}/${id}/edit`, data)
  },
  noFeedingProgram () {
    return repository.get(`${resource}/no-feeding-program`)
  },
  hasFeedingProgram () {
    return repository.get(`${resource}/has-feeding-program`)
  },
  hasFeedDetail () {
    return repository.get(`${resource}/has-feed-detail`)
  },
  noFeedDetail () {
    return repository.get(`${resource}/no-feed-detail`)
  },
  updateUnits (id, data) {
    return repository.put(`${resource}/${id}/units`, data)
  },
  storeUnits (id, data) {
    return repository.post(`${resource}/${id}/units`, data)
  },
  units (id) {
    return repository.get(`${resource}/${id}/units`)
  },
}
