<template lang="pug">
  div
    base-snackbar(
      :errors.sync="stockPutErrors"
    )
    stock-form(
      :input-fields.sync="stockFields"
      :default-field-values="stockData"
      :error-messages="stockPutErrors"
    )
    v-container
      v-btn(
        color="primary"
        dark
        small
        @click="update"
        :loading="stockPutting"
      )
        span Update
</template>
<script>
import stockRepository from '@/repositories/stock.repository'
import { putVars } from '@/libs/api-helper.extra'
import VuePostApi from '@/libs/classes/VueGetApi.class'

export default {
  name: 'EditStock',
  props: {
    stockData: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    stockForm: () => import('@/components/forms/StockForm'),
  },
  data: () => ({
    stockFields: {},
    ...putVars('stock'),
  }),
  methods: {
    update () {
      if (this.stockGetting) return
      const requestVarNames = this.getRequestGetVarNames('stock')
      const handler = new VuePostApi(this, requestVarNames)
      const repository = stockRepository.update
      const { data, id } = this.getStockFields()
      handler.execute(repository, [id, data], this.stockUpdateStatusOk)
    },
    stockUpdateStatusOk () {
      this.$emit('update:stockData', {})
      this.$emit('close-dialog')
    },
    getStockFields () {
      const { id } = this.stockData
      const stockFields = this.$objectFilterKeys(this.stockFields, ['id'])
      const data = this.$objectChangeKeyCase(stockFields, 'camelToSnakeCase')
      return { data, id }
    },
    getRequestGetVarNames (baseName) {
      return {
        loading: baseName + 'Putting',
        errors: baseName + 'PutErrors',
      }
    },
  },
}
</script>